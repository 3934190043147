import { default as tokenSlice } from "../tokenSlice";
import { default as api } from "../../api";
import { doiActions } from "../doiSlice";
import { commentActions } from "../commentSlice";
import { issueActions } from "../issueSlice";
import { aggregatedImagesActions } from "../aggregatedSlice";
import Cookies from "js-cookie";
import { doiList } from "../../doi_list";

export const checkToken = () => {
  return async (dispatch) => {
    // get token from cookie
    const token = Cookies.get("token") || null;
    !!token && dispatch(tokenSlice.actions.gotToken(token));
  };
};

export const fetchDois = (page, doisPerPage, total) => {
  return async (dispatch) => {
    const fetchHandler = async () => {
      return await api.get("/api/doi/", {
        params: {
          page: page,
          page_size: doisPerPage,
          submissions__comments__reference_images__image_types__contains: "gb",
        },
      });
    };
    try {
      const dois = await fetchHandler();
      let results = dois.data.results.filter((doi) => {
        return doiList.includes(doi.doi);
      });

      dispatch(doiActions.assignDois(results));
      dispatch(doiActions.changePage(page));
      dispatch(doiActions.assignCurrentDoisPerPage(results.length));
      dispatch(doiActions.assignTotal(dois.data.count));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchDoisByList = (dois, page) => {
  return async (dispatch) => {
    const fetchHandler = async (doi) => {
      return await api.get(`/api/doi/`, {
        params: {
          doi: doi,
        },
      });
    };
    try {
      let results = [];
      let currentDois = 0;
      for (let i = 0; i < dois.length; i++) {
        const result = await fetchHandler(dois[i]);
        if (result.data.results[0]) {
          ++currentDois;
          results.push(result.data.results[0]);
        }
      }
      dispatch(doiActions.assignDois(results));
      dispatch(doiActions.changePage(page));
      dispatch(doiActions.assignCurrentDoisPerPage(currentDois));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchDoi = (token, uuid, index) => {
  return async (dispatch) => {
    const fetchHandler = async () => {
      return await api.get(`/api/doi/${uuid}/`);
    };
    try {
      const doi = await fetchHandler();
      dispatch(doiActions.assignDoi(doi.data));
      dispatch(doiActions.assignIndex(index));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchDoiByName = (doi, setPage) => {
  return async (dispatch) => {
    const fetchHandler = async () => {
      return await api.get(`/api/doi/`, {
        params: {
          doi: doi,
        },
      });
    };
    try {
      let dois = await fetchHandler();
      let results = dois.data.results.filter((item) => {
        return item.submissions.length !== 0;
      });
      dispatch(doiActions.assignDois(results));
      dispatch(doiActions.changePage(1));
      setPage(1);
      dispatch(doiActions.assignCurrentDoisPerPage(results.length));
      dispatch(doiActions.assignTotal(dois.data.count));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchComments = (token, submissions) => {
  return async (dispatch) => {
    const fetchSubmissions = async (submissionUuid) => {
      return await api.get(`/api/submission/${submissionUuid}/`, {});
    };
    const fetchCommentNoIssues = async (uuid) => {
      return await api.get(`/api/comment/${uuid}/`, {});
    };

    try {
      if (submissions.length !== 0) {
        let comments = [];
        for (let i = 0; i < submissions.length; i++) {
          let fetchedComments = await fetchSubmissions(submissions[i]);
          comments.push(...fetchedComments.data.comments);
        }
        let actualComments = [];
        for (let i = 0; i < comments.length; i++) {
          let actualComment = await fetchCommentNoIssues(comments[i]);

          actualComments.push(actualComment.data);
        }
        dispatch(commentActions.assignComments(actualComments));
      } else {
        //can a doi not have any comments??
        dispatch(commentActions.assignComments([]));
      }
    } catch (error) {
      console.log(error.message);
    }
  };
};

//fetches a particular comment + assigns issues
export const fetchComment = (token, uuid, doi) => {
  return async (dispatch) => {
    const fetchHandler = async () => {
      return await api.get(`/api/comment/${uuid}/`, {});
    };
    try {
      const comment = await fetchHandler();
      dispatch(commentActions.assignComment(comment.data));
      dispatch(fetchIssues(comment.data.issues));
      dispatch(fetchRefImgsWithOrigImgs(commentActions, comment.data, doi));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchIssues = (uuids) => {
  return async (dispatch) => {
    const fetchHandler = async (uuid) => {
      return await api.get(`/api/issue/${uuid}/`, {});
    };
    try {
      let issues = uuids.length === 0 ? null : [];
      for (let i = 0; i < uuids.length; i++) {
        const issue = await fetchHandler(uuids[i]);
        issues.push(issue.data);
      }
      dispatch(issueActions.assignIssues(issues));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchIssuesUnderADoi = (comments) => {
  return async (dispatch) => {
    const fetchHandler = async (comment) => {
      return await api.get(`/api/issue/`, {
        params: {
          comment: comment.uuid,
        },
      });
    };
    try {
      let issues = [];
      for (let i = 0; i < comments.length; i++) {
        const issue = await fetchHandler(comments[i]);
        issues.push(...issue.data);
      }
      dispatch(doiActions.assignIssues(issues));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchOrigImg = (actions, payload, allowedOrigImgs) => {
  // payload is array of [index, imageObject]
  return async (dispatch) => {
    const fetchHandler = async (uuid) => {
      if (!!!uuid) return [];
      return await api.get(`/api/original-image/${uuid}/`, {
        params: {
          image_type__in: allowedOrigImgs,
        },
      });
    };
    try {
      const origImg = await fetchHandler(payload[1]);
      !!origImg && dispatch(actions.assignOrigImg([payload[0], origImg.data]));
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const fetchGalleryImg = (uuids) => {
  return async (dispatch) => {
    if (!uuids || uuids.length === 0) {
      dispatch(issueActions.assignSourceGalleryImages([]));
      return [];
    }
    const fetchHandler = async (uuid) => {
      const results = await api.get(`/api/original-image/${uuid}/`, {});
      return results.data;
    };
    try {
      let galleryImages = [];
      for (let i = 0; i < uuids.length; i++) {
        try {
          const results = await fetchHandler(uuids[i]);
          galleryImages.push(results);
        } catch (error) {
          console.log(error.message);
        }
      }
      dispatch(issueActions.assignSourceGalleryImages(galleryImages));
    } catch (error) {
      console.error(error.message);
    }
  };
};

export const fetchRefImgs = (actions, origImgs, uuids) => {
  return async (dispatch) => {
    const fetchHandler = async (uuid) => {
      return await api.get(`/api/reference-image/${uuid}/`, {});
    };
    let refImgs = [];
    for (let i = 0; i < uuids.length; i++) {
      try {
        const refImg = await fetchHandler(uuids[i]);
        refImgs.push(refImg.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    dispatch(actions.assignRefImgs(refImgs));
  };
};

export const fetchRefImgsWithOrigImgs = (actions, comment, doi) => {
  return async (dispatch) => {
    const fetchHandler = async (uuid) => {
      return await api.get(`/api/reference-image/${uuid}/`, {});
    };
    let refImgs = [];
    let uuids = comment.reference_images;
    for (let i = 0; i < uuids.length; i++) {
      try {
        const refImg = await fetchHandler(uuids[i]);
        refImgs.push(refImg.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    dispatch(actions.assignRefImgs(refImgs));
    let allowedImgTypes = [];
    refImgs.forEach((item) => {
      let indexOfFigure = -1;
      let toAppend = [...item.image_types_list];
      if (toAppend.includes("MICROSCOPY_PANEL") && toAppend.includes("MICROSCOPY_FIGURE")) {
        indexOfFigure = toAppend.indexOf("MICROSCOPY_PANEL");
      } else if (toAppend.includes("GB_PANEL") && toAppend.includes("GB_FIGURE")) {
        indexOfFigure = toAppend.indexOf("GB_PANEL");
      }
      if (indexOfFigure > -1) {
        toAppend.splice(indexOfFigure, 1);
      }
      allowedImgTypes.push(...toAppend);
    });
    //getting rid of non-unique types
    allowedImgTypes = [...new Set(allowedImgTypes)];
    allowedImgTypes = allowedImgTypes.filter((item) =>
      ["MICROSCOPY_PANEL", "MICROSCOPY_FIGURE", "GB_PANEL", "GB_FIGURE"].includes(item)
    );
    if (allowedImgTypes.length === 0) {
      dispatch(actions.assignOrigImgs([]));
      dispatch(actions.assignRefImgs([]));
    } else {
      dispatch(fetchOrigImgsWithFilter(actions, doi, comment.original_images, allowedImgTypes));
    }
  };
};

export const fetchOrigImgsWithFilter = (actions, doi, uuids, allowedImgTypes) => {
  return async (dispatch) => {
    const fetchHandler = async () => {
      return await api.get(`/api/original-image/`, {
        params: {
          doi: doi.uuid,
          image_type__in: encodeURI(allowedImgTypes),
        },
      });
    };
    try {
      const allFilteredImgs = await fetchHandler();
      let origImgs = allFilteredImgs.data.filter((item) => {
        return uuids.includes(item.uuid);
      });
      dispatch(actions.assignOrigImgs(origImgs));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchAggregatedImgs = (token, uuid) => {
  return async (dispatch) => {
    const fetchHandler = async () => {
      return await api.get(`/api/aggregated-image/${uuid}/`, {});
    };
    try {
      const aggregatedImg = await fetchHandler(uuid);
      dispatch(aggregatedImagesActions.addImage(aggregatedImg.data));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const fetchAggregatedImgsDownload = (token, uuid) => {
  return async (dispatch) => {
    const fetchHandler = async (uuid) => {
      return await api.get(`/api/aggregated-image/${uuid}/download/`, {
        responseType: "blob",
      });
    };
    try {
      const aggregatedImg = await fetchHandler(uuid);
      const url = window.URL.createObjectURL(new Blob([aggregatedImg.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "aggregate_image");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error.message);
    }
  };
};
