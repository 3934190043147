import { patchIntegrityComment } from "../../../store/functions/patchData";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ImageIntegrity = () => {
  const [open, setOpen] = useState(false);
  const commentId = useSelector((state) => state.comment?.comment?.uuid) || null;
  const commentIntegrityStatus = useSelector((state) => state.comment?.integrity) || null;
  const [integrity, setIntegrity] = useState(commentIntegrityStatus || null);
  const dispatch = useDispatch();

  // memoize integrity value

  const integrityValue = React.useMemo(() => {
    if (integrity !== commentIntegrityStatus) return integrity;
    return null;
  }, [integrity]);
  function handleClick() {
    setOpen(!open);
  }

  function onSelectIntegrity(integrityValue) {
    setOpen(!open);
    setIntegrity(integrityValue);
  }

  // This will assign the initial value of integrity
  React.useEffect(() => {
    setIntegrity(commentIntegrityStatus);
  }, [commentIntegrityStatus]);

  // Memoize patching function
  const patchIntegrity = React.useCallback(() => {
    !!integrityValue && !!commentId && dispatch(patchIntegrityComment(commentId, integrityValue));
  }, [integrityValue, commentId]);

  // Patching will happen only when integrity value changes
  React.useEffect(() => {
    patchIntegrity();
  }, [integrityValue]);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip arrow placement="top" title="Select image integrity type.">
        <Paper sx={{ flex: 0.4 }}>
          <List
            dense
            sx={{
              p: 0,
              m: 0,
              minWidth: "100%",
              backgroundColor: "#f8f8f8",
            }}
          >
            <ListItemButton onClick={handleClick} sx={{ maxHeight: 40 }}>
              <ListItemText primary={`Integrity: ${integrity}`} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{
                position: "absolute",
                backgroundColor: "#f8f8f8",
                maxHeight: "100px",
                width: "100%",
                zIndex: 10,
              }}
            >
              <Paper>
                <List component="div">
                  <ListItemButton onClick={() => onSelectIntegrity("NO_PROBLEM")}>
                    <ListItemText primary={"No problem"} />
                  </ListItemButton>
                  <ListItemButton onClick={() => onSelectIntegrity("SPLICING")}>
                    <ListItemText primary={"Image splicing"} />
                  </ListItemButton>
                  <ListItemButton onClick={() => onSelectIntegrity("DUPLICATION")}>
                    <ListItemText primary={"Duplication"} />
                  </ListItemButton>
                  <ListItemButton onClick={() => onSelectIntegrity("PLAGIARISM")}>
                    <ListItemText primary={"Plagiarism"} />
                  </ListItemButton>
                  <ListItemButton onClick={() => onSelectIntegrity("MISCELLANEOUS")}>
                    <ListItemText primary={"Miscellaneous"} />
                  </ListItemButton>
                </List>
              </Paper>
            </Collapse>
          </List>
        </Paper>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ImageIntegrity;
