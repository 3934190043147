import { List, ListItemText, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

//InfoBox component provides a pre-formatted box to display information to the user
const paperClass = {
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  backgroundColor: "#f4f4f4",
  overflow: "auto",
  width: "100%",
};
const InfoBox = (props) => {
  return (
    <Paper
      sx={{
        flex: 1,
        ...paperClass,
      }}
    >
      <List component="div" sx={{ py: 0, px: 2, height: "100%" }}>
        <ListItemText
          primaryTypographyProps={{
            component: "span",
            variant: "subtitle1",
          }}
          primary={props.bolded}
        />
        {props.link ? (
          <Link to={props.text} target="_blank" rel="noreferrer" underline="none">
            {props.text}
          </Link>
        ) : (
          <Typography>{props.text}</Typography>
        )}
      </List>
    </Paper>
  );
};

export default InfoBox;
/*

*/
