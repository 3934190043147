import InfoBox from "../../../components/common_components/InfoBox";
import SrcImages from "./SrcImages";
import RefImages from "./RefImages";
import { Button, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import CommentBox from "./CommentBox";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { postIssue } from "../../../store/functions/postData";
import { patchIssue } from "../../../store/functions/patchData";
import ImageIntegrity from "./ImageIntegrity";

//ImageBox component displays the interactive part of the UI: the source and reference images that have to be filtered
//by the user, the issue description that has to be provided by the user, plus information like comment description and pubpeer link;
//it sends the data for the images to the corresponding Src- or RefImages components

const ImageBox = () => {
  const comment = useSelector((state) => state.comment.comment);
  const issue = useSelector((state) => state.issue.issue);
  const issues = useSelector((state) => state.issue.issues);
  const token = useSelector((state) => state.token.token);
  const doi = useSelector((state) => state.doi.doi);
  let origImgsIssue = useSelector((state) => state.issue.origImgs);
  let origImgsComment = useSelector((state) => state.comment.origImgs);
  let refImgsIssue = useSelector((state) => state.issue.refImgs);
  let refImgsComment = useSelector((state) => state.comment.refImgs);
  let text = null,
    refImgs = null,
    origImgs = null;
  let dispatch = useDispatch();
  if (!comment) {
    refImgs = [];
    origImgs = [];
  } else if (!issue) {
    origImgs = origImgsComment;
    refImgs = refImgsComment;
  } else {
    origImgs = origImgsIssue;
    refImgs = refImgsIssue;
  }
  if (comment) {
    text = comment.source_url;
  }

  const [descriptionValue, setDescriptionValue] = useState(() => (issue ? issue.description : ""));
  const integrity = useSelector((state) => state.comment?.integrity);

  const [chosenRefImages, setChosenRefImages] = useState([]);
  const [chosenSrcImages, setChosenSrcImages] = useState([]);
  const gallerySourceImages = useSelector((state) => state.issue.gallerySourceImage1s);

  useEffect(() => {
    //the rendered images will change based on whether an issue is selected + which issue
    setDescriptionValue(() => (issue ? issue.description : ""));
    if (issue && refImgsIssue && origImgsIssue) {
      setChosenRefImages(refImgsIssue);
      setChosenSrcImages(origImgsIssue);
    } else {
      setChosenRefImages([]);
      setChosenSrcImages([]);
    }
  }, [doi, issue, issues]);
  const changedDescription = (e) => {
    setDescriptionValue(e.target.value);
  };

  const handleCreateIssue = () => {
    let issueDescription = descriptionValue;
    let commentId = comment.uuid;
    if (chosenSrcImages.length === 0) {
      window.alert("At least a source image needs to be selected in order to create a new issue.");
    } else if (chosenRefImages.length === 0) {
      window.alert(
        "At least a reference image needs to be selected in order to create a new issue."
      );
    } else if (issueDescription === "") {
      window.alert("Write a description before creating a new issue.");
    } else {
      dispatch(
        postIssue(token, issueDescription, commentId, chosenSrcImages, chosenRefImages, doi.uuid)
      );
    }
  };
  const handleEditIssue = () => {
    if (chosenRefImages.length === 0 || chosenSrcImages.length === 0) {
      window.alert("At least an image from each pile has to be selected.");
    } else {
      let issueDescription = descriptionValue;
      let commentId = comment.uuid;
      let date = issue.created_at;

      dispatch(
        patchIssue(
          token,
          issue.uuid,
          issueDescription,
          commentId,
          date,
          chosenSrcImages,
          chosenRefImages
        )
      );
    }
  };

  React.useEffect(() => {
    if (!!gallerySourceImages && gallerySourceImages.length > 0) {
      setChosenSrcImages([...gallerySourceImages]);
      refImgsIssue && setChosenRefImages([...refImgsIssue]);
    }
  }, [gallerySourceImages]);
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          flex: 0.05,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, height: 120 }}>
          <CommentBox />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <InfoBox bolded="Pubpeer link:" text={text} link={true} />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          flex: 0.9,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ mt: 2, display: "flex", gap: 4 }}>
          <RefImages
            refImgs={refImgs}
            chosenRefImages={chosenRefImages}
            setChosenRefImages={setChosenRefImages}
          />
          <SrcImages
            origImgs={origImgs}
            chosenSrcImages={chosenSrcImages}
            setChosenSrcImages={setChosenSrcImages}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          pb: 1,
          flex: 0.05,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography gutterBottom variant="body1" sx={{ fontWeight: "bold" }}>
            <label htmlFor="issue-description">Issue Title:</label>
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextareaAutosize
              required
              style={{ width: "80%", resize: "none", fontSize: "1rem" }}
              aria-label="issue-description"
              name="issue-description"
              id="issue-description"
              minRows={5}
              maxRows={5}
              value={descriptionValue}
              onChange={(e) => changedDescription(e)}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, paddingLeft: 1 }}>
              <ImageIntegrity />

              <Tooltip
                arrow
                placement="top"
                title={!issue ? "To edit an issue, first select one by clicking on it." : null}
              >
                <span>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    size="medium"
                    disabled={!issue}
                    onClick={handleEditIssue}
                  >
                    Edit issue
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                arrow
                placement="top"
                title={
                  issue
                    ? "To create a new issue, first deselect the current one."
                    : comment?.integrity === "PLAGIARISM"
                    ? "You are unable to create issues for comments that are marked as plagiarism"
                    : null
                }
              >
                <span>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    size="medium"
                    disabled={!!issue || integrity === "PLAGIARISM"}
                    onClick={handleCreateIssue}
                  >
                    Create issue
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ImageBox;
