import { createSlice } from "@reduxjs/toolkit";

//slice which holds all comments, the current comment and its reference and original images as objects
const commentSlice = createSlice({
  name: "comment",
  initialState: {
    comments: null,
    comment: null,
    integrity: null,
    refImgs: null,
    origImgs: null,
  },
  reducers: {
    assignComments(state, action) {
      state.comments = action.payload;
    },
    assignComment(state, action) {
      state.comment = action.payload;
    },
    assignIntegrity(state, action) {
      state.integrity = action.payload;
    },
    assignRefImgs(state, action) {
      state.refImgs = action.payload;
    },
    assignOrigImgs(state, action) {
      state.origImgs = action.payload;
    },
    assignOrigImg(state, action) {
      state.origImgs = Array.isArray(state.origImgs) ? [...state.origImgs] : [];
      const arr = [...state.origImgs];
      // putting each image in corriding position
      arr[action.payload[0]] = action.payload[1];
      state.origImgs = arr;
    },
    deassignComment(state) {
      state.comments = null;
      state.comment = null;
      state.refImgs = null;
      state.origImgs = null;
    },
  },
});

export const commentActions = commentSlice.actions;

export default commentSlice;
