import Main from "./pages/DoiViewPage";
import "./style/style.css";
import { Routes, Route } from "react-router-dom";
import IssueUpload from "./pages/IssueUploadPage/IssueUpload";
import { useEffect, useState } from "react";
import { dummyData } from "./dummyData";
import DoiChoice from "./pages/DoiChoicePage";
import { checkToken } from "./store/functions/fetchData";
import { useDispatch } from "react-redux";
import LoginPage from "./pages/LoginPage";
import PrivateRoutes from "./components/util/PrivateRoutes/PrivateRoutes";
import Layout from "./components/Layout";

function App() {
  const dispatch = useDispatch();

  const [issueArray, setIssueArray] = useState(dummyData.doi.issues);
  useEffect(() => {
    dispatch(checkToken());
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<DoiChoice />} />
          <Route
            path="comment"
            element={<Main issueArray={issueArray} setIssueArray={setIssueArray} />}
          />
          <Route
            path="upload"
            element={<IssueUpload issueArray={issueArray} setIssueArray={setIssueArray} />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
