import { default as api } from "../../api";
import { issueActions } from "../issueSlice";

export const deleteIssue = (token, issue, issues) => {
  return async (dispatch) => {
    const deleteHandler = async (token, issueId) => {
      await api.delete("/api/issue/" + issueId + "/", {
        headers: {
          Authorization: "Token " + token,
        },
      });
    };
    try {
      await deleteHandler(token, issue.uuid);
      let copyOfIssues = new Array(...issues);
      let indexToDelete = copyOfIssues.indexOf(issue);
      copyOfIssues.splice(indexToDelete, 1);
      dispatch(issueActions.deleteIssue(copyOfIssues));
    } catch (error) {
      console.log(error.message);
    }
  };
};
