import { IconButton, List, ListItem, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import ConditionalText from "../../../components/common_components/ConditionalText";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { issueActions } from "../../../store/issueSlice";
import { deleteIssue } from "../../../store/functions/deleteData";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/modals/DeleteModal";
import { fetchGalleryImg, fetchRefImgs } from "../../../store/functions/fetchData";

//The IssueList component renders links to the issues created by the user

const IssueList = ({ issueArray, currentIssue, setCurrentIssue }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.token);
  const issue = useSelector((state) => state.issue.issue);
  const issues = useSelector((state) => state.issue.issues);
  const [issueToDelete, setIssueToDelete] = useState(null);

  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);

  useEffect(() => {
    //this function runs if the currentIssue changes;
    //if an issue is selected, the api endpoints for the originalImages and referenceImages will be called
    if (issueArray) {
      if (currentIssue === null) dispatch(fetchGalleryImg([]));
      if (currentIssue >= issueArray.length) {
        setCurrentIssue(null);
        dispatch(issueActions.assignIssue(null));
      }

      if (issueArray[currentIssue]) {
        dispatch(fetchRefImgs(issueActions, token, issueArray[currentIssue].reference_images));
        dispatch(fetchGalleryImg(issueArray[currentIssue].original_images));
      }

      if (!issue && (currentIssue || currentIssue === 0)) {
        setCurrentIssue(null);
      }
    }
  }, [currentIssue, issueArray, issue]);

  const selectIssue = (index) => {
    //set the currentIssue
    if (index === currentIssue) {
      setCurrentIssue(null);
      dispatch(issueActions.assignIssue(null));
    } else {
      setCurrentIssue(index);
      dispatch(issueActions.assignIssue(issueArray[index]));
    }
  };

  function addBorder(index) {
    if (index === currentIssue) return { border: "2px solid magenta", borderRadius: "5px" };
    return {};
  }

  const isVisible = (index) => {
    if (!issue) return "hidden";
    if (index === currentIssue) return "visible";
    return "hidden";
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setIssueToDelete(issue);
    setIsDeleteModelOpen(true);
  };

  const handleClose = () => {
    setIsDeleteModelOpen(false);
  };

  const handelDeleteIssue = () => {
    dispatch(deleteIssue(token, issueToDelete, issues));
    setIssueToDelete(null);
    handleClose();
  };

  function doesIssueHaveLabels(issue) {
    if (issue.labels.length !== 0) {
      return true;
    }
  }
  return (
    <>
      <ConditionalText
        condition={!issueArray}
        true={"No issues created"}
        false={"Issues created:"}
      />

      <List component="ol" sx={{ listStyleType: "decimal", fontSize: "1.25rem" }}>
        {!!issueArray?.length
          ? issueArray.map((item, index) => {
              return (
                item && (
                  <ListItem
                    key={index}
                    id={"issue" + index}
                    sx={{ display: "flex-item", p: 0, ...addBorder(index), gap: 4 }}
                    onClick={() => selectIssue(index)}
                  >
                    <ListItemButton>
                      <Tooltip
                        arrow
                        placement="left"
                        key={index}
                        title={
                          doesIssueHaveLabels(item)
                            ? "Issue has labels"
                            : "Issue doesn't have labels"
                        }
                      >
                        <ListItemText
                          primary={item.description}
                          primaryTypographyProps={{
                            color: doesIssueHaveLabels(item) ? "green" : "common.black",
                            fontWeight: "medium",
                            variant: "body2",
                          }}
                        />
                      </Tooltip>
                      <IconButton sx={{ visibility: isVisible(index) }} onClick={handleClickOpen}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemButton>
                  </ListItem>
                )
              );
            })
          : null}
      </List>

      <DeleteModal
        modalTitle={"Are you sure you want to delete this issue?"}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes, delete it"}
        setIsOpen={setIsDeleteModelOpen}
        onConfirm={handelDeleteIssue}
        isOpen={isDeleteModelOpen}
      />
    </>
  );
};

export default IssueList;
