import React from "react";
import LoginForm from "./components/LoginForm";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function LoginPage() {
  let navigate = useNavigate();
  let token = useSelector((state) => state.token.token) || null;

  React.useEffect(() => {
    if (token) {
      return navigate("/");
    }
  }, [token]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ flexDirection: "column" }}
      component="section"
    >
      <LoginForm />
    </Box>
  );
}

export default LoginPage;
