import {
  IconButton,
  Paper,
  Box,
  Typography,
  CircularProgress,
  ButtonBase,
  Tooltip,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import React, { useRef, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import { useSelector } from "react-redux";
//The ImageSlider component renders the frame out of which images are selected;
//it offers functions to interact with the data, like scrolling through the unselected images and
//adding clicked images to the chosenImages prop;

const ImageSlider = ({ rejectedImages, chosenImages, setChosenImages, origin }) => {
  const srcImagesUuidList = useSelector((state) => state.comment?.origImgs);
  const refImagesUuidList = useSelector((state) => state.comment?.comment.reference_images);
  const imageUuidList = origin === "r" ? refImagesUuidList : srcImagesUuidList;

  //displayedImage - variable to indicate the index of the image to display out of the rejectedImages array prop
  const [displayedImageIndex, setDisplayedImageIndex] = useState(0);
  // Dimension
  const [zoomedDimensions, setZoomedDimensions] = useState({ width: 400, height: 400 });
  //a couple of constants, for easy access in case there is a need to change them
  const IMAGE_DIMENSION = 400;
  const ZOOM_FACTOR = 2;

  let typographyRef = useRef();

  //variable to display tally of remaining images
  let toDisplay = rejectedImages?.length === 0 ? 0 : displayedImageIndex + 1;

  const word = origin === "r" ? "Reference" : "Source";
  const leftid = origin + "-left-slider";
  const rightid = origin + "-right-slider";
  const mainid = origin + "-main-slider";

  //TODO: put all memos and functions inside a hook

  const isImageSelected = React.useMemo(() => {
    const uuid = rejectedImages?.[displayedImageIndex]?.uuid ?? null;
    let chosenIdArray = chosenImages?.map((item) => item.uuid);
    return chosenIdArray?.includes(uuid);
  }, [chosenImages, origin, displayedImageIndex]);

  const isAllImagesDone = React.useMemo(() => {
    return rejectedImages?.length !== 0;
  }, [rejectedImages, displayedImageIndex]);

  //update chosenImages prop to add the displayed image to it
  function addImageToMap() {
    setChosenImages((array) => [...array, rejectedImages[displayedImageIndex]]);
  }

  //runs when the displayed image is clicked
  function imageClicked() {
    if (!chosenImages.includes(rejectedImages[displayedImageIndex])) addImageToMap();
  }

  function rightArrowButtonClick() {
    if (displayedImageIndex < imageUuidList.length - 1) {
      setDisplayedImageIndex((index) => index + 1);
    } else {
      setDisplayedImageIndex(0);
    }
  }

  function leftArrowButtonClick() {
    if (displayedImageIndex === 0 && imageUuidList.length !== 0) {
      setDisplayedImageIndex(imageUuidList.length - 1);
    } else if (imageUuidList.length === 0) {
      setDisplayedImageIndex(0);
    } else {
      setDisplayedImageIndex((index) => index - 1);
    }
  }

  return (
    <>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        {word} images: {toDisplay}/{imageUuidList?.length}
      </Typography>
      <Box className="slider">
        <IconButton aria-label="left" id={leftid} onClick={leftArrowButtonClick}>
          <WestIcon fontSize="inherit" />
        </IconButton>
        <Tooltip
          disableInteractive
          placement={"top-end"}
          arrow
          title={isImageSelected ? null : "Click to select"}
          enterDelay={1000}
        >
          <Paper
            elevation={1}
            sx={{
              width: IMAGE_DIMENSION,
              height: IMAGE_DIMENSION,
              margin: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              objectFit: "contain",
              position: "relative",
            }}
          >
            {isImageSelected && (
              <Typography
                sx={{
                  position: "absolute",
                  zIndex: 6,
                  opacity: 0.6,
                  transform: "rotate(-45deg)",
                  fontSize: "5rem",
                }}
                ref={typographyRef}
                variant="h3"
                color="success.main"
              >
                {isAllImagesDone ? "Selected" : "Done"}
              </Typography>
            )}
            {isAllImagesDone ? (
              !!rejectedImages?.[displayedImageIndex]?.image_file ? (
                <ButtonBase
                  onClick={imageClicked}
                  onMouseOver={() => {
                    let element = typographyRef.current;
                    if (element) element.style.display = "none";
                  }}
                  onMouseOut={() => {
                    let element = typographyRef.current;
                    if (element) element.style.display = "block";
                  }}
                >
                  <ReactImageMagnify
                    id={mainid}
                    {...{
                      enlargedImagePosition: "over",
                      isHintEnabled: true,
                      enlargedImageContainerStyle: {
                        visibility: "unset",
                      },
                      smallImage: {
                        alt: origin + "-mainimg",
                        width: IMAGE_DIMENSION,
                        height: IMAGE_DIMENSION,
                        src: rejectedImages[displayedImageIndex].image_file,
                        onLoad: (load) =>
                          setZoomedDimensions({
                            height: load.target.height * ZOOM_FACTOR,
                            width: load.target.width * ZOOM_FACTOR,
                          }),
                      },

                      largeImage: {
                        alt: origin + "-mainimg",
                        src: rejectedImages[displayedImageIndex].image_file,
                        ...zoomedDimensions,
                      },
                      imageStyle: {
                        objectFit: "contain",
                      },
                      enlargedImageStyle: {
                        objectFit: "contain",
                      },
                    }}
                  />
                </ButtonBase>
              ) : (
                <CircularProgress />
              )
            ) : (
              <span>All done!</span>
            )}
          </Paper>
        </Tooltip>

        <IconButton aria-label="left" id={rightid} onClick={rightArrowButtonClick}>
          <EastIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </>
  );
};

export default ImageSlider;
