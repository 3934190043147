import { Button, Container, Input, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import IssueCheckbox from "./components/IssueCheckbox";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import ConditionalText from "../../components/common_components/ConditionalText";
import { useRef } from "react";
import { patchCreateAnnotationImage } from "../../store/functions/patchData";
import {
  postCreateLabelAnnotations,
  postCreateZipArchiveAggregatedImages,
} from "../../store/functions/postData";
import { fetchIssues, fetchIssuesUnderADoi } from "../../store/functions/fetchData";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IssueUpload = () => {
  //IssueUpload - component to download and upload issues manually
  let issueArray = useSelector((state) => state.doi.issues);
  let comments = useSelector((state) => state.comment.comments);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let dispatch = useDispatch();
  let uploadFileRef = useRef();
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [totalAnnotations, setTotalAnnotations] = useState(0);
  const [successfulAnnotations, setSuccessfulAnnotations] = useState(0);

  useEffect(() => {
    dispatch(fetchIssuesUnderADoi(comments));
  }, []);

  async function downloadFiles() {
    //this function will download the files in the Downloads folder of the user
    //the dispatch function calls the api endpoint that returns the image blob and downloads it
    if (selectedIssues.length === 0) window.alert("No issues have been selected.");
    else {
      let issueIds = [];
      new Promise((resolve, reject) => {
        try {
          let count = 0;
          selectedIssues.forEach((element) => {
            issueIds.push(issueArray[element].uuid);
            let promise = dispatch(
              patchCreateAnnotationImage(issueArray[element].uuid, issueArray[element].description)
            );
            promise
              .then(() => {
                ++count;
                if (count === selectedIssues.length) resolve();
              })
              .catch(() => {
                reject();
              });
          });
        } catch (error) {
          reject(error);
        }
      })
        .then(() => {
          if (issueIds.length > 0) dispatch(postCreateZipArchiveAggregatedImages(issueIds));
        })

        .catch((error) => {
          alert(error);
        });
    }
  }

  function uploadFiles() {
    //Question: should I patch **all** aggregated images under the selected issue?
    //For now, I'll do it only for the first one

    // this function gets the files uploaded by the user and adds them to the root issueArray
    let files = Array.from(uploadFileRef.current.files);
    let status = dispatch(postCreateLabelAnnotations(files[0]));
    status.then((res) => {
      setTotalAnnotations(Object.keys(res.data).length);
      let success = 0;
      for (const key in res.data) {
        if (res.data[key]) ++success;
      }
      setSuccessfulAnnotations(success);
      let issueIds = issueArray.flatMap((item) => item.uuid);
      dispatch(fetchIssues(issueIds));
      handleOpen();
    });
  }

  return (
    <Container sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
        <Button variant="contained" size="medium">
          <label
            htmlFor="upload"
            name="upload"
            style={{
              cursor: "pointer",
            }}
          >
            Upload annotations
            <br />
            <Input
              inputRef={uploadFileRef}
              type="file"
              id="upload"
              onChange={uploadFiles}
              sx={{ display: "none" }}
            />
          </label>
        </Button>
        <Button
          component={Link}
          size="medium"
          to="/comment"
          sx={{ float: "right", marginLeft: "15px", boxSizing: "border-box" }}
        >
          Back
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 5 }}>
        <ConditionalText
          condition={issueArray?.length === 0 || !issueArray}
          true={"No issues created"}
          false={"Issues created:"}
        />
        {issueArray && (
          <IssueCheckbox
            issueArray={issueArray}
            selectedIssues={selectedIssues}
            setSelectedIssues={setSelectedIssues}
          />
        )}
        <Button
          type="submit"
          variant="contained"
          size="medium"
          onClick={(e) => {
            downloadFiles();
          }}
          sx={{ mt: 2 }}
        >
          Download
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="annotation-success"
        aria-describedby="annotation-successful-or-not"
      >
        <Box sx={style}>
          <Typography variant="h6">Success</Typography>
          <Typography>
            {successfulAnnotations} out of {totalAnnotations} annotations were successfully
            uploaded.
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default IssueUpload;
