import { Box, Typography } from "@mui/material";
import ImageGallery from "./ImageGallery";
import ImageSlider from "./ImageSlider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
//SrcImages component uses the TemplateImages component to render the data and interact with the user;
//the origin prop is used to determine the ids and classes that will be used (example: the button that scrolls to the left
//will have the id=origin+"-mainimage-left")

const SrcImages = (props) => {
  const origin = "s";
  // let origImgs = props.origImgs;
  let origImgs = useSelector((state) => state.comment.origImgs);

  const [rejectedImages, setRejectedImages] = useState([]);
  let chosenImages = props.chosenSrcImages;
  let setChosenImages = props.setChosenSrcImages;

  useEffect(() => {
    // We wont remove images from the rejectedImages array anymore, instead we will show
    // selected watermarked images
    !!origImgs && setRejectedImages([...origImgs]);
  }, [chosenImages, origImgs]);
  return origImgs ? (
    <Box className={props.origin + "-images"} sx={{ width: 600 }}>
      <ImageSlider
        origin={origin}
        rejectedImages={rejectedImages}
        chosenImages={chosenImages}
        setChosenImages={setChosenImages}
      />
      <Box className="mini-gallery" sx={{ position: "relative" }}>
        <Typography
          sx={{ width: 40, position: "absolute", left: 0 }}
          align="center"
          variant="subtitle2"
          color="initial"
        >
          Chosen images ({chosenImages.length})
        </Typography>
        <ImageGallery
          origin={origin}
          dummyData={origImgs}
          chosenImages={chosenImages}
          setChosenImages={setChosenImages}
        />
      </Box>
    </Box>
  ) : (
    <Box
      className={props.origin + "-images"}
      sx={{ width: 600, display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress />
    </Box>
  );
};

export default SrcImages;
