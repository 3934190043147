import { Container } from "@mui/system";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Container maxWidth="xl" component="main">
      <Outlet />
    </Container>
  );
};

export default Layout;
