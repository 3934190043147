import React from "react";
import { Button, TextField, IconButton, Tooltip } from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import useLoginPage from "../useLoginPage";
const LoginForm = () => {
  const usernameRef = React.useRef(null);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const { handleSubmit } = useLoginPage();

  React.useEffect(() => {
    usernameRef.current.focus();
  }, []);
  const isFormValid = username.length > 0 && password.length > 0;
  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e, { username, password })}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "25vw" }}
    >
      <TextField
        inputRef={usernameRef}
        size="large"
        variant="outlined"
        label="Username"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
        sx={{
          marginBottom: "1rem",
          width: "100%",
        }}
      />
      <TextField
        label="Password"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setShowPassword((prev) => !prev)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ),
        }}
        sx={{ marginBottom: "1rem", width: "100%" }}
      />
      <Tooltip title={!isFormValid ? "Please fill in all fields" : undefined}>
        <Box component="span" sx={{ width: "100%" }}>
          <Button
            sx={{ width: "100%" }}
            disabled={!isFormValid}
            type="submit"
            onClick={(e) => handleSubmit(e, { username, password })}
            variant="contained"
          >
            Login
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default LoginForm;
