import { default as api } from "../../api";
import { fetchComment } from "./fetchData";

export const postIssue = (token, description, commentId, origImgs, refImgs, doi) => {
  return async (dispatch) => {
    const postHandler = async (token, reqBody) => {
      await api.post("/api/issue/", { ...reqBody }, {});
    };
    try {
      let origIds = [];
      let refIds = [];
      origImgs.forEach((element) => {
        origIds.push(element.uuid);
      });
      refImgs.forEach((element) => {
        refIds.push(element.uuid);
      });
      let reqBody = {
        comment: commentId,
        description: description,
        created_at: new Date(),
        duplication_type: "SI",
        labeled: true,
        original_images: origIds,
        reference_images: refIds,
      };

      await postHandler(token, reqBody);
      dispatch(fetchComment(token, commentId));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const postCreateZipArchiveAggregatedImages = (issueUuids) => {
  return async (dispatch) => {
    const postHandler = async () => {
      const data = await api.post(
        "/api/issue/create_zip_archive_aggregated_images/",

        {
          issues: issueUuids,
        },
        {
          responseType: "blob",
        }
      );
      return data;
    };
    try {
      let response = await postHandler();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "aggregate_images.zip");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const postCreateLabelAnnotations = (file) => {
  return async () => {
    const postHandler = async () => {
      return await api.post(
        "/api/label/create_label_annotations/",
        {
          makesense_file: file,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
    };
    try {
      let response = await postHandler();
      return response;
    } catch (error) {
      if (error.message.slice(error.message.length - 3, error.message.length) === "400") {
        window.alert("Wrong file type was uploaded.");
      } else {
        window.alert("An error occurred.");
      }
      return 400;
    }
  };
};
