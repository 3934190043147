import React from "react";
import { Dialog, Box, Button, DialogActions, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
function DeleteModal({
  onConfirm,
  isOpen,
  setIsOpen,
  modalTitle = "delete",
  cancelButtonText = "NO",
  confirmButtonText = "Confirm",
}) {
  return (
    <Box>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setIsOpen(false)}>
            {cancelButtonText}
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              setIsOpen(false);
            }}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DeleteModal;

// experimenting proptypes
DeleteModal.propTypes = {
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  modalTitle: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
};
