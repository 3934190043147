import axios from "axios";
export const url = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});
// Central error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);
export default api;
