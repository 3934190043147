import { CircularProgress, IconButton, ImageList, ImageListItem } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//ImageGallery component is used to display the images selected by the user;
//it offers functions to interact with the data, like scrolling through the gallery or deleting images;

const ImageGallery = (props) => {
  //generate ids corresponding to source or reference images
  let origin = props.origin;
  let leftid = origin + "-left-gallery";
  let rightid = origin + "-right-gallery";

  //generate the DOM for the ImageListItems
  let rows = [];

  function addImagesInContainers() {
    let minNumberImages = Math.min(5, props.chosenImages.length);

    //for loop to generate the 5 or less non-empty image containers
    for (let i = 0; i < minNumberImages; i++) {
      rows.push(
        <ImageListItem
          component="div"
          id={origin + "-image" + i}
          key={i}
          sx={{ height: "100%", border: "1px solid black" }}
        >
          <img
            className="x-img"
            src="x.png"
            alt={"option " + i}
            style={{ height: "inherit", objectFit: "contain" }}
            onClick={() => {
              deleteFromContainers(i);
            }}
          />
          {props.chosenImages[i].image_file !== undefined ? (
            <img
              src={props.chosenImages[i].image_file}
              alt={"img" + i}
              loading="lazy"
              style={{ height: "inherit", objectFit: "contain" }}
            />
          ) : (
            <CircularProgress />
          )}
        </ImageListItem>
      );
    }

    //for loop to generate the remaining empty containers, if there are any
    for (let i = minNumberImages; i < 5; i++) {
      rows.push(
        <ImageListItem
          component="div"
          id={origin + "-image" + i}
          key={i}
          sx={{ border: "1px solid black" }}
        />
      );
    }
  }

  addImagesInContainers();

  //shift gallery images to the right by 1 position
  function leftClick() {
    if (props.chosenImages.length > 5) {
      let lastValue = props.chosenImages.pop();
      props.chosenImages.unshift(lastValue);
      props.setChosenImages((array) => [...array]);
    }
  }

  //shift gallery images to the left by 1 position
  function rightClick() {
    if (props.chosenImages.length > 5) {
      let firstValue = props.chosenImages.shift();
      props.setChosenImages((array) => [...array, firstValue]);
    }
  }

  //delete image with index i from gallery
  function deleteFromContainers(i) {
    let map = [...props.chosenImages];
    map.splice(i, 1);
    props.setChosenImages(() => [...map]);
  }

  return (
    <>
      <IconButton aria-label="left" id={leftid} onClick={leftClick}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <ImageList
        component="div"
        cols={5}
        gap={2}
        rowHeight={50}
        sx={{ width: "65%", margin: "0px 5px" }}
      >
        {rows.map((item) => item)}
      </ImageList>
      <IconButton aria-label="right" id={rightid} onClick={rightClick}>
        <ArrowForwardIosIcon />
      </IconButton>
    </>
  );
};

export default ImageGallery;
