import { createSlice } from "@reduxjs/toolkit";

//slice which holds a dictionary between issue IDs and array of aggregated_image objects
const aggregatedImagesSlice = createSlice({
  name: "aggregatedImages",
  initialState: {
    aggregatedImgs: [],
  },
  reducers: {
    assignImages(state, action) {
      state.aggregatedImgs = action.payload;
    },
    addImage(state, action) {
      state.aggregatedImgs = [...state.aggregatedImgs, action.payload];
    },
    deassignImages(state) {
      state.aggregatedImgs = [];
    },
  },
});

export const aggregatedImagesActions = aggregatedImagesSlice.actions;

export default aggregatedImagesSlice;
