import React from "react";
import IssueBox from "./components/IssueBox";
import ImageBox from "./components/ImageBox";
import { dummyData } from "../../dummyData";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchComments } from "../../store/functions/fetchData";
import { Box } from "@mui/material";
import GuideModal from "../../components/modals/GuideModal";
//Main component to render the app; is used to fetch the data from the API and pass it down
//accordingly to each component; will handle rerendering when user chooses a new issue

const Main = (props) => {
  const isNotShowAgain = localStorage.getItem("isNotShowAgain");
  const [showGuide, setShowGuide] = React.useState(
    !isNotShowAgain || isNotShowAgain === "false" ? true : false
  );
  const issueArray = props.issueArray;
  const dispatch = useDispatch();
  const doi = useSelector((state) => state.doi.doi);
  const newDoiIncoming = useSelector((state) => state.doi.newDoiIncoming);
  const token = useSelector((state) => state.token.token);
  const comments = useSelector((state) => state.comment.comments);

  useEffect(() => {
    if (!newDoiIncoming && doi && !comments) dispatch(fetchComments(token, doi.submissions));
  }, [newDoiIncoming, doi, comments]);

  return (
    <Box sx={{ display: "flex", height: "98vh" }}>
      <IssueBox dummyData={dummyData.doi} issueArray={issueArray} />
      <ImageBox dummyData={dummyData.doi.comment} />
      <GuideModal open={showGuide} setOpen={setShowGuide} />
    </Box>
  );
};

export default Main;
