import { default as api } from "../../api";
import { commentActions } from "../commentSlice";
import { issueActions } from "../issueSlice";

export const patchIssue = (token, uuid, description, commentId, date, origImgs, refImgs) => {
  return async (dispatch) => {
    const patchHandler = async (token, reqBody) => {
      await api.patch("/api/issue/" + uuid + "/", { ...reqBody }, {});
    };
    try {
      let origIds = [];
      let refIds = [];
      origImgs.forEach((element) => {
        origIds.push(element.uuid);
      });
      refImgs.forEach((element) => {
        refIds.push(element.uuid);
      });
      let reqBody = {
        comment: commentId,
        description: description,
        created_at: date,
        duplication_type: "SI",
        labeled: true,
        original_images: origIds,
        reference_images: refIds,
      };

      await patchHandler(token, reqBody);
      let issue = { uuid: uuid, ...reqBody };
      dispatch(issueActions.updateAnIssue(issue));
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const patchIntegrityComment = (uuid, integrityValue) => {
  return async (dispatch) => {
    const patchHandler = async (integrity_status) => {
      await api.patch(`/api/comment/${uuid}/`, { integrity_status });
    };
    try {
      await patchHandler(integrityValue);
      dispatch(commentActions.assignIntegrity(integrityValue));
    } catch (error) {
      console.log(error.message);
    }
  };
};

//Apparently this function is supposed to work in a different way entirely, so we can
//let it rest for a while
export const patchAggregatedImg = (token, uuid, issueUuid, makesenseResult) => {
  return async (dispatch) => {
    const patchHandler = async (body) => {
      await api.patch("/api/aggregated-image/" + uuid + "/", makesenseResult, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };
    try {
      let newBody = {
        issue: issueUuid,
        makesense_result: makesenseResult,
      };
      await patchHandler(newBody);
      window.alert("Annotation was successfully uploaded.");
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const patchCreateAnnotationImage = (issueUuid, issueName) => {
  return async (dispatch) => {
    const patchHandler = async () => {
      return await api.patch("/api/issue/" + issueUuid + "/create_annotation_image/", {});
    };
    try {
      let response = await patchHandler();
      return response.data;
    } catch (error) {
      alert("Unable to upload annotation for issue " + issueName);
    }
  };
};
