import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen }) {
  const [isNotShowAgain, setIsNotShowAgain] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  function ControlledCheckbox() {
    const handleChange = (event) => {
      localStorage.setItem("isNotShowAgain", event.target.checked);

      setIsNotShowAgain(event.target.checked);
    };

    return (
      <Box sx={{ display: "flex" }}>
        <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
          <FormControlLabel
            control={
              <Checkbox
                size={"small"}
                checked={isNotShowAgain}
                onChange={handleChange}
                name="dont-show-it-again"
              />
            }
            componentsProps={{ typography: { variant: "body2" } }}
            label="Please don't show this again"
          />
        </FormControl>
      </Box>
    );
  }

  return (
    <Dialog
      PaperProps={{
        sx: { width: "100%", height: "auto", position: "fixed", top: 10, m: 0, p: 2 },
      }}
      open={open}
      maxWidth="lg"
      hideBackdrop
      fullWidth
      transitionDuration={{ enter: 800, exit: 500 }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Good to know!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Read the comment and choose those original images from the right which seem to be a part
          of the reference image on the left. Add an "issue title" to your problem described in the
          comment.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <ControlledCheckbox />
        <Button sx={{ minWidth: 100 }} variant="contained" onClick={handleClose}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
