import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import api from "../api";

export const login = createAsyncThunk(
  "login/post",
  async (credentialsObject, { rejectWithValue }) => {
    const { data, error } = await api.post("/auth-token/", credentialsObject);
    if (error) {
      return rejectWithValue(error);
    }

    return data;
  }
);
//slice which holds the token for authentication
const initialState = {
  token: null,
  isFetched: false,
  loading: false,
  error: null,
};
const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    reset: () => initialState,
    logout: (state, action) => {
      Cookies.remove("token");
      state.token = null;
      state.isFetched = false;
      state.loading = false;
      state.error = null;
    },
    gotToken(state, action) {
      state.token = action.payload;
      state.isFetched = true;
      api.defaults.headers.common["Authorization"] = "Token " + state.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        // action is inferred correctly here if using TS
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload.token;
        // setting the cookie to expire in 12 hours
        Cookies.set("token", action.payload.token, {
          expires: 0.5,
          secure: process.env.NODE_ENV === "production",
        });
        // Setting the token in the header for future requests
        api.defaults.headers.common["Authorization"] = "Token " + action.payload.token;
        state.isFetched = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        alert(action?.error?.message || "Something went wrong with the login.");
        tokenSlice.caseReducers.reset();
        state.error = action.error;
        console.error(state.error);
      });
  },
});

export default tokenSlice;
