import { Box, CircularProgress, Typography } from "@mui/material";
import ImageGallery from "./ImageGallery";
import ImageSlider from "./ImageSlider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//RefImages component uses the TemplateImages component to render the data and interact with the user;
//the origin prop is used to determine the ids and classes that will be used (example: the button that scrolls to the left
//will have the id=origin+"-mainimage-left")

const RefImages = (props) => {
  const origin = "r";
  const [rejectedImages, setRejectedImages] = useState([]);
  let refImgs = useSelector((state) => state.comment.refImgs);
  let chosenImages = props.chosenRefImages;
  let setChosenImages = props.setChosenRefImages;

  useEffect(() => {
    //In a nutshell, each time chosenImages is changed, rejectedImages is also changed;
    //this function makes sure that the images in rejected- and chosenImages are never repeated
    setRejectedImages(() => {
      if (refImgs) return [...refImgs];
      return [];
    });
  }, [chosenImages, refImgs]);

  return refImgs ? (
    <Box className={props.origin + "-images"} sx={{ width: 600 }}>
      <ImageSlider
        origin={origin}
        rejectedImages={rejectedImages}
        chosenImages={chosenImages}
        setChosenImages={setChosenImages}
      />
      <Box className="mini-gallery" sx={{ position: "relative" }}>
        <Typography
          sx={{ width: 40, position: "absolute", left: 0 }}
          align="center"
          variant="subtitle2"
          color="initial"
        >
          Chosen images ({chosenImages.length})
        </Typography>
        <ImageGallery
          origin={origin}
          dummyData={[]}
          chosenImages={chosenImages}
          setChosenImages={setChosenImages}
        />
      </Box>
    </Box>
  ) : (
    <Box
      className={props.origin + "-images"}
      sx={{ width: 600, display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress />
    </Box>
  );
};

export default RefImages;
