import { createSlice } from "@reduxjs/toolkit";

//slice which holds all issues, reference + original images (if the issue is different than null) as objects
const issueSlice = createSlice({
  name: "issue",
  initialState: {
    issues: null,
    issue: null,
    refImgs: null,
    origImgs: null,
    gallerySourceImages: [],
    galleryReferenceImages: [],
  },
  reducers: {
    assignIssues(state, action) {
      state.issues = action.payload;
    },
    assignIssue(state, action) {
      state.issue = action.payload;
    },
    assignRefImgs(state, action) {
      state.refImgs = action.payload;
    },
    assignOrigImgs(state, action) {
      state.origImgs = action.payload;
    },
    assignOrigImg(state, action) {
      state.origImgs = Array.isArray(state.origImgs) ? [...state.origImgs] : [];
      const arr = [...state.origImgs];
      // putting each image in corriding position
      arr[action.payload[0]] = action.payload[1];
      state.origImgs = arr;
    },
    deleteIssue(state, action) {
      state.issues = action.payload;
      state.issue = null;
      state.origImgs = null;
      state.refImgs = null;
    },
    updateAnIssue(state, action) {
      let newIssue = action.payload;
      let currentIssues = state.issues;

      let updated = false;
      for (let i = 0; i < state.issues.length; i++) {
        if (state.issues[i].uuid.localeCompare(newIssue.uuid) === 0) {
          currentIssues[i] = newIssue;
          updated = true;
        }
      }
      if (updated) {
        state.issues = currentIssues;
        state.issue = null;
        state.refImgs = null;
        state.origImgs = null;
      } else {
        console.log("Didn't find issue to update.");
      }
    },
    deassignIssue(state) {
      state.issue = null;
      state.issues = null;
      state.refImgs = null;
      state.origImgs = null;
      state.aggregatedImgs = null;
    },
    assignSourceGalleryImages(state, action) {
      state.gallerySourceImages = action.payload;
    },
    assignReferenceGalleryImages(state, action) {
      state.galleryReferenceImages = action.payload;
    },
  },
});

export const issueActions = issueSlice.actions;

export default issueSlice;
