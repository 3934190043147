import { useDispatch, useSelector } from "react-redux";
import { commentActions } from "../store/commentSlice";
import { issueActions } from "../store/issueSlice";
import { doiActions } from "../store/doiSlice";
import { aggregatedImagesActions } from "../store/aggregatedSlice";
import { fetchDoi, fetchDoisByList } from "../store/functions/fetchData";
import { doiList } from "../doi_list";

const useHandleDoi = () => {
  const dispatch = useDispatch();
  const currentIndex = useSelector((state) => state.doi.index);
  const dois = useSelector((state) => state.doi.dois);
  const token = useSelector((state) => state.token.token);
  const numberOfPages = useSelector((state) => state.doi.numberOfPages);
  //doisPerPage - the initial fetched number of dois for all pages
  const doisPerPage = useSelector((state) => state.doi.doisPerPage);
  //currentDoisPerPage - the number of dois on this page, after filtering
  const currentDoisPerPage = useSelector((state) => state.doi.currentDoisPerPage);
  const currentPage = useSelector((state) => state.doi.currentPage);

  function exitDoi() {
    //delete all pre-fetched information
    dispatch(doiActions.deassignDoi());
    dispatch(commentActions.deassignComment());
    dispatch(issueActions.deassignIssue());
    dispatch(aggregatedImagesActions.deassignImages());
  }

  const changeDoi = () => {
    dispatch(doiActions.assignWait());

    if (dois[currentIndex + 1]?.uuid) {
      dispatch(fetchDoi(token, dois[currentIndex + 1].uuid, currentIndex + 1));
      dispatch(issueActions.deassignIssue());
      dispatch(commentActions.deassignComment());
    }
  };
  const nextDoi = () => {
    if (currentIndex + 1 === currentDoisPerPage && currentPage < numberOfPages) {
      let doiArray = doiList.slice(doisPerPage * currentPage, doisPerPage * (currentPage + 1) - 1);
      dispatch(fetchDoisByList(doiArray, currentPage + 1));
      dispatch(doiActions.changePage(currentPage + 1));
      dispatch(doiActions.assignIndex(-1));
    }
    changeDoi();
  };

  return { exitDoi, nextDoi, changeDoi };
};

export default useHandleDoi;
