import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Tooltip,
} from "@mui/material";

const IssueCheckbox = (props) => {
  //IssueCheckbox - component to generate a list of checkboxes for every issue
  let issueArray = props.issueArray;
  let setSelectedIssues = props.setSelectedIssues;
  let selectedIssues = props.selectedIssues;

  const addToSelectedIssues = (e, index) => {
    setSelectedIssues(() => {
      let newArray = selectedIssues.filter((el) => el !== index);
      if (!e.target.checked) return newArray;
      return [...newArray, index];
    });
  };

  function doesIssueHaveLabels(issue) {
    if (issue.labels.length !== 0) {
      return true;
    }
  }

  return (
    <FormControl>
      <FormLabel id="checkbox-group-label">Issues</FormLabel>
      <FormGroup>
        {!!issueArray?.length
          ? issueArray.map((item, index) => {
              return (
                <Tooltip
                  arrow
                  placement="right"
                  key={index}
                  title={
                    doesIssueHaveLabels(item) ? "Issue has labels" : "Issue doesn't have labels"
                  }
                >
                  <FormControlLabel
                    sx={{ pr: 1, color: doesIssueHaveLabels(item) ? "green" : null }}
                    control={<Checkbox onChange={(e) => addToSelectedIssues(e, index)} />}
                    label={item.description}
                    value={item.description}
                  />
                </Tooltip>
              );
            })
          : null}
      </FormGroup>
    </FormControl>
  );
};

export default IssueCheckbox;
