// Login form logic should go here
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import tokenSlice, { login } from "../../store/tokenSlice";

function useLoginPage() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.token);

  // Setting Cookies if the response is successful
  React.useEffect(() => {
    !!token && dispatch(tokenSlice.actions.gotToken(token));
  }, []);

  const handleSubmit = async (event, credentials) => {
    event.preventDefault();
    dispatch(login(credentials));
  };
  return {
    handleSubmit,
  };
}
export default useLoginPage;
