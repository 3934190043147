import InfoBox from "../../../components/common_components/InfoBox";
import IssueList from "./IssueList";
import { Button, IconButton, Paper, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMemo, useState } from "react";
import useHandleDoi from "../../../hooks/useHandleDoi";
import useLogout from "../../../hooks/useLogout";
//IssueBox component is used to display information about the doi number and the issues created by the user;
//also controls what information is displayed to the user in the ImageBox component, based on the selected issue

const IssueBox = () => {
  const [currentIssue, setCurrentIssue] = useState(null);
  const issueArray = useSelector((state) => state.issue.issues);
  const doi = useSelector((state) => state.doi.doi);
  const currentIndex = useSelector((state) => state.doi.index);
  const dois = useSelector((state) => state.doi.dois);
  const currentPage = useSelector((state) => state.doi.currentPage);
  const numberOfPages = useSelector((state) => state.doi.numberOfPages);
  const commentIntegrityStatus = useSelector((state) => state.comment?.integrity);
  const navigate = useNavigate();
  const logout = useLogout();
  let number = doi?.doi;

  const { exitDoi, nextDoi } = useHandleDoi();

  const isButtonDisabled = useMemo(() => {
    if (!commentIntegrityStatus) {
      return true;
    }
    if (dois?.[currentIndex + 1]) {
      return false;
    }
    return currentPage !== numberOfPages ? false : true;
  }, [dois, currentIndex, currentPage, commentIntegrityStatus]);

  return (
    <Paper
      sx={{
        minWidth: 300,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        maxHeight: "100%",
        px: 1,
        pb: 2,
      }}
      elevation={3}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          gap: 3,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", gap: 1 }}>
          <InfoBox bolded="DOI:" text={number} />
          <Tooltip title="Logout">
            <IconButton
              sx={{ height: 50, width: 50 }}
              color="warning"
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ width: "80%" }} className="issues">
          <IssueList
            issueArray={issueArray}
            currentIssue={currentIssue}
            setCurrentIssue={setCurrentIssue}
          />
        </Box>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/upload"
          sx={{ width: "80%" }}
        >
          Go to Active Issues
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Tooltip
          arrow
          placement="top"
          title={!commentIntegrityStatus ? "Select image integrity first." : null}
        >
          <Box>
            <Button
              variant="contained"
              size="small"
              onClick={nextDoi}
              id="nextDoi"
              disabled={isButtonDisabled}
              sx={{ width: "100%" }}
            >
              Next DOI
            </Button>
          </Box>
        </Tooltip>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/"
          sx={{ width: "100%" }}
          className="display-block"
          onClick={() => {
            exitDoi();
          }}
        >
          Back to DOI selection
        </Button>
      </Box>
    </Paper>
  );
};

export default IssueBox;
