import { Typography } from "@mui/material";

//component to render different texts according to the incoming value
const ConditionalText = (props) => {
  let condition = props.condition;
  let trueText = props.true;
  let falseText = props.false;
  let variant = props.variant || "h6";
  return (
    <>
      {condition ? (
        <Typography variant={variant}>{trueText}</Typography>
      ) : (
        <Typography variant={variant}>{falseText}</Typography>
      )}
    </>
  );
};

export default ConditionalText;
