export const doiList = [
  "10.1038/s41586-021-03489-0",
  "10.1038/s41586-021-03225-8",
  "10.1038/s41586-022-04847-2",
  "10.1038/s41586-019-0989-6",
  "10.1038/s41586-022-04774-2",
  "10.1038/s41586-019-1443-5",
  "10.1038/ncb3395",
  "10.1038/s41586-021-03422-5",
  "10.1038/s41586-020-2683-0",
  "10.1038/s41586-021-03857-w",
  "10.1038/s41556-022-00944-6",
  "10.1038/s41556-021-00702-0",
  "10.1038/s41586-019-0979-8",
  "10.1038/s41586-019-1515-6",
  "10.1038/s41586-022-04593-5",
  "10.1038/nature14274",
  "10.1038/s41586-022-05347-z",
  "10.1038/s41556-022-00991-z",
  "10.1038/s41586-022-04596-2",
  "10.1038/s41556-020-0522-z",
  "10.1038/nature23275",
  "10.1038/ncb3445",
  "10.1038/s41586-018-0714-x",
  "10.1038/s41556-020-00583-9",
  "10.1038/s41586-018-0709-7",
  "10.1038/s41586-020-2074-6",
  "10.1038/s41556-020-0486-z",
  "10.1038/s41586-022-04522-6",
  "10.1038/s41556-021-00723-9",
  "10.1038/s41586-021-04384-4",
  "10.1038/s41586-018-0499-y",
  "10.1038/s41586-021-04013-0",
  "10.1038/s41586-020-2375-9",
  "10.1038/s41586-022-05254-3",
  "10.1038/nature19326",
  "10.1038/s41586-018-0316-7",
  "10.1038/s41586-020-2801-z",
  "10.1038/nature12745",
  "10.1038/s41586-021-03536-w",
  "10.1038/nature22821",
  "10.1038/s41586-018-0016-3",
  "10.1038/s41586-019-1511-x",
  "10.1038/s41556-020-0529-5",
  "10.1038/nature22341",
  "10.1038/s41586-022-04722-0",
  "10.1038/s41586-021-04330-4",
  "10.1038/s41586-019-1487-6",
  "10.1038/nature14067",
  "10.1038/s41586-018-0128-9",
  "10.1038/s41586-019-1870-3",
  "10.1038/sj.onc.1209211",
  "10.1038/onc.2014.272",
  "10.1038/s41586-019-1069-7",
  "10.1038/nature14344",
  "10.1038/s41586-021-03762-2",
  "10.1038/ncb1847",
  "10.1038/nature14231",
  "10.1038/onc.2016.197",
  "10.1038/s41586-019-1383-0",
  "10.1038/ncb3082",
  "10.1038/s41586-019-0916-x",
  "10.1038/s41586-018-0847-y",
  "10.1038/onc.2016.425",
  "10.1038/ncb3169",
  "10.1038/s41556-021-00639-4",
  "10.1038/onc.2014.22",
  "10.1038/s41586-019-1783-1",
  "10.1038/ncb3039",
  "10.1038/s41586-020-1979-4",
  "10.1038/s41586-022-04814-x",
  "10.1038/s41388-020-01446-1",
  "10.1038/s41586-018-0050-1",
  "10.1038/onc.2016.151",
  "10.1038/s41388-018-0505-8",
  "10.1038/ncb2090",
  "10.1038/ncb3139",
  "10.1038/nature13848",
  "10.1038/onc.2017.159",
  "10.1038/ncb2860",
  "10.1038/s41586-020-2184-1",
  "10.1038/nature21423",
  "10.1038/onc.2017.380",
  "10.1038/s41586-020-2071-9",
  "10.1038/nature17997",
  "10.1038/nature20587",
  "10.1038/s41586-020-03116-4",
  "10.1038/ncb3423",
  "10.1038/nature14484",
  "10.1038/onc.2016.19",
  "10.1038/nature13948",
  "10.1038/ncb1648",
  "10.1038/ncb2784",
  "10.1038/s41586-020-03129-z",
  "10.1038/s41586-018-0243-7",
  "10.1038/s41388-019-0954-8",
  "10.1038/s41586-019-0945-5",
  "10.1038/s41388-018-0128-0",
  "10.1038/nature12998",
  "10.1038/s41586-018-0186-z",
  "10.1038/onc.2015.281",
  "10.1038/ncb2099",
  "10.1038/ncb2910",
  "10.1038/s41388-021-02079-8",
  "10.1038/s41556-020-0558-0",
  "10.1038/onc.2017.199",
  "10.1038/nature12968",
  "10.1038/nature18268",
  "10.1038/s41388-019-0926-z",
  "10.1038/ncb1857",
  "10.1038/onc.2013.184",
  "10.1038/s41388-020-1338-9",
  "10.1038/s41586-019-1678-1",
  "10.1038/onc.2016.369",
  "10.1038/s41388-020-1378-1",
  "10.1038/onc.2016.323",
  "10.1038/nature21076",
  "10.1038/s41388-019-0904-5",
  "10.1038/s41388-018-0416-8",
  "10.1038/onc.2017.387",
  "10.1038/s41586-020-2229-5",
  "10.1038/ncb3209",
  "10.1038/385810a0",
  "10.1038/s41388-020-01442-5",
  "10.1038/onc.2015.259",
  "10.1038/onc.2016.277",
  "10.1038/s41388-018-0453-3",
  "10.1038/onc.2014.445",
  "10.1038/s41586-018-0425-3",
  "10.1038/s41388-019-0803-9",
  "10.1038/nature12587",
  "10.1038/nature25989",
  "10.1038/nature21671",
  "10.1038/343567a0",
  "10.1038/ncb2324",
  "10.1038/ncb3581",
  "10.1038/s41388-019-0934-z",
  "10.1038/onc.2013.220",
  "10.1038/nature16549",
  "10.1038/s41586-019-1024-7",
  "10.1038/nature22067",
  "10.1038/nature17392",
  "10.1038/ncb2052",
  "10.1038/nature13110",
  "10.1038/nature13441",
  "10.1038/s41556-018-0215-z",
  "10.1038/nature14581",
  "10.1038/s41388-019-0806-6",
  "10.1038/s41556-017-0010-2",
  "10.1038/nature17950",
  "10.1038/onc.2010.465",
  "10.1038/ncb3268",
  "10.1038/nature20132",
  "10.1038/onc.2011.23",
  "10.1038/s41388-018-0311-3",
  "10.1038/nature12878",
  "10.1038/nature21036",
  "10.1038/s41388-020-01543-1",
  "10.1038/sj.onc.1206199",
  "10.1038/nature16187",
  "10.1038/nature03080",
  "10.1038/s41388-022-02498-1",
  "10.1038/s41388-020-01486-7",
  "10.1038/s41388-018-0351-8",
  "10.1038/s41556-017-0006-y",
  "10.1038/nature13264",
  "10.1038/nature20131",
  "10.1038/nature13605",
  "10.1038/ncb1626",
  "10.1038/nature20789",
  "10.1038/s41586-021-03715-9",
  "10.1038/ncb2468",
  "10.1038/ncb1121",
  "10.1038/ncb3207",
  "10.1038/onc.2015.223",
  "10.1038/onc.2016.106",
  "10.1038/onc.2016.75",
  "10.1038/s41388-020-01574-8",
  "10.1038/s41388-018-0432-8",
  "10.1038/ncb1928",
  "10.1038/onc.2014.397",
  "10.1038/s41388-022-02394-8",
  "10.1038/s41388-021-01761-1",
  "10.1038/s41388-020-1254-z",
  "10.1038/ncb1613",
  "10.1038/sj.onc.1208881",
  "10.1038/s41388-021-02111-x",
  "10.1038/onc.2016.261",
  "10.1038/s41388-020-01443-4",
  "10.1038/ncb1516",
  "10.1038/s41388-020-01483-w",
  "10.1038/sj.onc.1207573",
  "10.1038/s41388-020-01555-x",
  "10.1038/onc.2016.419",
  "10.1038/onc.2012.525",
  "10.1038/onc.2012.625",
  "10.1038/s41388-021-02132-6",
  "10.1038/nature13261",
  "10.1038/s41388-018-0215-2",
  "10.1038/s41388-022-02189-x",
  "10.1038/nature24637",
  "10.1038/s41388-019-1092-z",
  "10.1038/onc.2014.129",
  "10.1038/nature15520",
  "10.1038/sj.onc.1209323",
  "10.1038/onc.2016.308",
  "10.1038/onc.2014.165",
  "10.1038/nature12940",
  "10.1038/onc.2015.456",
  "10.1038/sj.onc.1211015",
  "10.1038/s41586-019-1296-y",
  "10.1038/onc.2017.378",
  "10.1038/s41388-019-0959-3",
  "10.1038/s41586-022-04961-1",
  "10.1038/s41586-018-0213-0",
  "10.1038/ncb3643",
  "10.1038/nature13013",
  "10.1038/onc.2011.365",
  "10.1038/s41388-018-0301-5",
  "10.1038/nature24994",
  "10.1038/onc.2014.55",
  "10.1038/ncb1052",
  "10.1038/onc.2011.378",
  "10.1038/nature13452",
  "10.1038/nature14492",
  "10.1038/nature13290",
  "10.1038/sj.onc.1206854",
  "10.1038/onc.2012.342",
  "10.1038/nature14363",
  "10.1038/onc.2012.217",
  "10.1038/nature18306",
  "10.1038/s41388-022-02280-3",
  "10.1038/onc.2011.641",
  "10.1038/nature25783",
  "10.1038/s41556-018-0183-3",
  "10.1038/nature23007",
  "10.1038/ncb1969",
  "10.1038/s41586-021-03618-9",
  "10.1038/nature12969",
  "10.1038/ncb2080",
  "10.1038/onc.2012.527",
  "10.1038/onc.2012.416",
  "10.1038/s41388-019-0902-7",
  "10.1038/s41556-018-0141-0",
  "10.1038/s41388-020-1199-2",
  "10.1038/onc.2014.228",
  "10.1038/s41388-021-01671-2",
  "10.1038/ncb838",
  "10.1038/onc.2010.300",
  "10.1038/s41388-019-0885-4",
  "10.1038/nature14418",
  "10.1038/onc.2011.204",
  "10.1038/nature24057",
  "10.1038/nature09557",
  "10.1038/ncb2057",
  "10.1038/ncb3195",
  "10.1038/ncb1864",
  "10.1038/onc.2017.371",
  "10.1038/ncb1929",
  "10.1038/s41388-018-0289-x",
  "10.1038/ncb1819",
  "10.1038/onc.2014.184",
  "10.1038/s41388-019-0970-8",
  "10.1038/sj.onc.1208897",
  "10.1038/onc.2015.334",
  "10.1038/onc.2013.519",
  "10.1038/nature21365",
  "10.1038/sj.onc.1209997",
  "10.1038/onc.2011.405",
  "10.1038/sj.onc.1208499",
  "10.1038/onc.2008.381",
  "10.1038/ncb1610",
  "10.1038/nature05282",
  "10.1038/sj.onc.1210601",
  "10.1038/ncb3273",
  "10.1038/onc.2011.321",
  "10.1038/nature09865",
  "10.1038/onc.2015.492",
  "10.1038/ncb2611",
  "10.1038/s41388-020-1303-7",
  "10.1038/ncb1732",
  "10.1038/onc.2015.355",
  "10.1038/sj.onc.1206981",
  "10.1038/onc.2010.380",
  "10.1038/onc.2015.168",
  "10.1038/sj.onc.1208579",
  "10.1038/s41388-018-0565-9",
  "10.1038/ncb1170",
  "10.1038/onc.2015.522",
  "10.1038/onc.2012.519",
  "10.1038/onc.2009.400",
  "10.1038/sj.onc.1206061",
  "10.1038/sj.onc.1209358",
  "10.1038/onc.2012.419",
  "10.1038/onc.2016.163",
  "10.1038/sj.onc.1208801",
  "10.1038/onc.2011.10",
  "10.1038/sj.onc.1209852",
  "10.1038/onc.2014.389",
  "10.1038/onc.2008.193",
  "10.1038/onc.2008.188",
  "10.1038/onc.2014.440",
  "10.1038/ncb3429",
  "10.1038/onc.2016.174",
  "10.1038/ncb2286",
  "10.1038/s41586-019-1192-5",
  "10.1038/ncb1614",
  "10.1038/d41586-019-00857-9",
  "10.1038/ncb1913",
  "10.1038/ncb2643",
  "10.1038/ncb3229",
  "10.1038/s41388-018-0526-3",
  "10.1038/s41388-021-01959-3",
  "10.1038/onc.2010.164",
  "10.1038/onc.2012.574",
  "10.1038/nature09347",
  "10.1038/onc.2011.607",
  "10.1038/35074506",
  "10.1038/onc.2012.182",
  "10.1038/ncb1272",
  "10.1038/sj.onc.1210162",
  "10.1038/onc.2013.478",
  "10.1038/sj.onc.1209804",
  "10.1038/nature00870",
  "10.1038/sj.onc.1208168",
  "10.1038/nature09730",
  "10.1038/onc.2010.509",
  "10.1038/ncb1691",
  "10.1038/ncb2901",
  "10.1038/onc.2009.293",
  "10.1038/nature09307",
  "10.1038/sj.onc.1204606",
  "10.1038/s41586-020-2937-x",
  "10.1038/d41586-021-00141-9",
  "10.1038/onc.2012.236",
  "10.1038/onc.2011.368",
  "10.1038/onc.2008.476",
  "10.1038/s41388-020-01547-x",
  "10.1038/onc.2015.10",
  "10.1038/nature06534",
  "10.1038/nature10538",
  "10.1038/onc.2015.214",
  "10.1038/onc.2010.181",
  "10.1038/sj.onc.1206328",
  "10.1038/sj.onc.1210091",
  "10.1038/nature09591",
  "10.1038/sj.onc.1208831",
  "10.1038/sj.onc.1206478",
  "10.1038/onc.2008.122",
  "10.1038/onc.2012.526",
  "10.1038/sj.onc.1204666",
  "10.1038/sj.onc.1206535",
  "10.1038/onc.2013.252",
  "10.1038/sj.onc.1205067",
  "10.1038/sj.onc.1208025",
  "10.1038/sj.onc.1206616",
  "10.1038/onc.2011.198",
  "10.1038/sj.onc.1202682",
  "10.1038/onc.2016.209",
  "10.1038/s41388-020-01517-3",
  "10.1038/sj.onc.1206738",
  "10.1038/nature10107",
  "10.1038/nature11745",
  "10.1038/onc.2010.218",
  "10.1038/onc.2009.467",
  "10.1038/onc.2010.65",
  "10.1038/onc.2012.97",
  "10.1038/ncb1684",
  "10.1038/sj.onc.1207814",
  "10.1038/nature10805",
  "10.1038/sj.onc.1206891",
  "10.1038/sj.onc.1208474",
  "10.1038/onc.2013.237",
  "10.1038/nature10179",
  "10.1038/sj.onc.1207835",
  "10.1038/sj.onc.1207342",
  "10.1038/sj.onc.1207462",
  "10.1038/s41388-020-1373-6",
  "10.1038/sj.onc.1206303",
  "10.1038/onc.2013.241",
  "10.1038/sj.onc.1206164",
  "10.1038/sj.onc.1207403",
  "10.1038/sj.onc.1205018",
  "10.1038/nature02316",
  "10.1038/sj.onc.1208802",
  "10.1038/sj.onc.1210572",
  "10.1038/nature07034",
  "10.1038/onc.2011.634",
  "10.1038/sj.onc.1203078",
  "10.1038/onc.2011.24",
  "10.1038/sj.onc.1207057",
  "10.1038/sj.onc.1205428",
  "10.1038/sj.onc.1210088",
  "10.1038/onc.2009.468",
  "10.1038/sj.onc.1205761",
  "10.1038/sj.onc.1208735",
  "10.1038/onc.2011.562",
  "10.1038/sj.onc.1209905",
  "10.1038/sj.onc.1205199",
  "10.1038/onc.2012.74",
  "10.1038/sj.onc.1209802",
  "10.1038/sj.onc.1209812",
  "10.1038/onc.2008.434",
  "10.1038/ncb1259",
  "10.1038/nature01784",
  "10.1038/sj.onc.1206365",
  "10.1038/onc.2009.446",
  "10.1038/onc.2011.229",
  "10.1038/sj.onc.1209026",
  "10.1038/onc.2008.6",
  "10.1038/onc.2011.425",
  "10.1038/nature11087",
  "10.1038/sj.onc.1206530",
  "10.1038/sj.onc.1208812",
  "10.1038/nature12080",
  "10.1038/sj.onc.1209761",
  "10.1038/sj.onc.1207636",
  "10.1038/nature10849",
  "10.1038/sj.onc.1210628",
  "10.1038/onc.2010.605",
  "10.1038/sj.onc.1209532",
  "10.1038/sj.onc.1207969",
  "10.1038/sj.onc.1200941",
  "10.1038/sj.onc.1209951",
  "10.1038/nature03068",
  "10.1038/onc.2013.376",
  "10.1038/nature02421",
  "10.1038/ncb1534",
  "10.1038/sj.onc.1207272",
  "10.1038/onc.2014.167",
  "10.1038/sj.onc.1204487",
  "10.1038/nature04674",
  "10.1038/onc.2011.281",
  "10.1038/onc.2009.339",
  "10.1038/sj.onc.1207879",
  "10.1038/sj.onc.1210446",
  "10.1038/sj.onc.1209339",
  "10.1038/onc.2013.586",
  "10.1038/26683",
  "10.1038/sj.onc.1206613",
  "10.1038/nature12147",
  "10.1038/onc.2009.81",
  "10.1038/onc.2010.598",
  "10.1038/sj.onc.1208950",
  "10.1038/nature03478",
  "10.1038/onc.2010.41",
  "10.1038/sj.onc.1208004",
  "10.1038/nature04695",
  "10.1038/sj.onc.1210831",
  "10.1038/nature09342",
  "10.1038/nature10598",
  "10.1038/onc.2009.233",
  "10.1038/ncb1147",
  "10.1038/ncb866",
  "10.1038/onc.2008.356",
  "10.1038/nature10167",
  "10.1038/onc.2009.98",
  "10.1038/sj.onc.1207054",
  "10.1038/sj.onc.1208790",
  "10.1038/sj.onc.1206693",
  "10.1038/onc.2010.570",
  "10.1038/sj.onc.1207616",
  "10.1038/sj.onc.1209542",
  "10.1038/sj.onc.1208402",
  "10.1038/sj.onc.1210855",
  "10.1038/nature07217",
  "10.1038/sj.onc.1210844",
  "10.1038/onc.2011.595",
  "10.1038/sj.onc.1210774",
  "10.1038/nature09459",
  "10.1038/sj.onc.1209267",
  "10.1038/sj.onc.1207589",
  "10.1038/onc.2011.423",
  "10.1038/onc.2011.100",
  "10.1038/sj.onc.1202104",
  "10.1038/onc.2008.77",
  "10.1038/sj.onc.1209737",
  "10.1038/sj.onc.1209945",
  "10.1038/onc.2011.454",
  "10.1038/sj.onc.1208128",
  "10.1038/sj.onc.1205038",
  "10.1038/nature04585",
  "10.1038/nature04979",
  "10.1038/sj.onc.1207407",
  "10.1038/sj.onc.1207279",
  "10.1038/ncb1296",
  "10.1038/nature05349",
  "10.1038/nature11782",
  "10.1038/sj.onc.1203339",
  "10.1038/sj.onc.1209660",
  "10.1038/onc.2008.480",
  "10.1038/nature08702",
  "10.1038/nature07782",
  "10.1038/sj.onc.1206579",
  "10.1038/sj.onc.1205006",
  "10.1038/ncb1598",
  "10.1038/onc.2009.479",
  "10.1038/onc.2010.325",
  "10.1038/sj.onc.1206829",
  "10.1038/sj.onc.1210797",
  "10.1038/sj.onc.1206750",
  "10.1038/nature11395",
  "10.1038/sj.onc.1209575",
  "10.1038/sj.onc.1202769",
  "10.1038/sj.onc.1208425",
  "10.1038/nature03314",
  "10.1038/sj.onc.1208065",
  "10.1038/onc.2012.39",
  "10.1038/ncb1098",
  "10.1038/sj.onc.1210660",
  "10.1038/nature07534",
  "10.1038/nature05020",
  "10.1038/ncb1415",
  "10.1038/onc.2008.319",
  "10.1038/sj.onc.1209405",
  "10.1038/sj.onc.1208815",
  "10.1038/ncb1026",
  "10.1038/onc.2010.179",
  "10.1038/ncb824",
  "10.1038/onc.2008.384",
  "10.1038/sj.onc.1208570",
  "10.1038/sj.onc.1206010",
  "10.1038/nature10809",
  "10.1038/sj.onc.1208586",
  "10.1038/onc.2012.393",
  "10.1038/sj.onc.1206306",
  "10.1038/sj.onc.1207353",
  "10.1038/sj.onc.1210964",
  "10.1038/onc.2013.115",
  "10.1038/sj.onc.1202116",
  "10.1038/sj.onc.1208786",
  "10.1038/sj.onc.1207406",
  "10.1038/sj.onc.1203939",
  "10.1038/sj.onc.1209401",
  "10.1038/sj.onc.1206691",
  "10.1038/s41586-018-0337-2",
  "10.1038/sj.onc.1204999",
  "10.1038/sj.onc.1207240",
  "10.1038/sj.onc.1206776",
  "10.1038/sj.onc.1204288",
  "10.1038/onc.2010.416",
  "10.1038/sj.onc.1208014",
  "10.1038/sj.onc.1206069",
  "10.1038/sj.onc.1210496",
  "10.1038/nature11058",
  "10.1038/ncb1077",
  "10.1038/onc.2016.327",
  "10.1038/onc.2011.296",
  "10.1038/onc.2012.505",
  "10.1038/nature11331",
  "10.1038/sj.onc.1207790",
  "10.1038/nature10398",
  "10.1038/sj.onc.1204799",
  "10.1038/29814",
  "10.1038/sj.onc.1210109",
  "10.1038/nature04656",
  "10.1038/nature11912",
  "10.1038/onc.2012.107",
  "10.1038/sj.onc.1205884",
  "10.1038/414660a",
  "10.1038/sj.onc.1209025",
  "10.1038/nature08020",
  "10.1038/sj.onc.1206708",
  "10.1038/sj.onc.1207655",
  "10.1038/37789",
  "10.1038/sj.onc.1209569",
  "10.1038/sj.onc.1207582",
  "10.1038/sj.onc.1207325",
  "10.1038/onc.2011.618",
  "10.1038/sj.onc.1208776",
  "10.1038/29555",
  "10.1038/nature11309",
  "10.1038/onc.2009.214",
  "10.1038/onc.2008.291",
  "10.1038/sj.onc.1203231",
  "10.1038/sj.onc.1210499",
  "10.1038/onc.2008.221",
  "10.1038/sj.onc.1208093",
  "10.1038/sj.onc.1203284",
  "10.1038/nature12234",
  "10.1038/nature09585",
  "10.1038/sj.onc.1208666",
  "10.1038/onc.2014.137",
  "10.1038/sj.onc.1208809",
  "10.1038/nature04019",
  "10.1038/sj.onc.1201680",
  "10.1038/sj.onc.1201791",
  "10.1038/onc.2008.412",
  "10.1038/sj.onc.1210794",
  "10.1038/sj.onc.1208098",
  "10.1038/nature05904",
  "10.1038/nature07091",
  "10.1038/47520",
  "10.1038/sj.onc.1208622",
  "10.1038/sj.onc.1208492",
  "10.1038/nature01075",
  "10.1038/sj.onc.1207404",
  "10.1038/onc.2008.64",
  "10.1038/416560a",
  "10.1038/sj.onc.1207776",
  "10.1038/nature10777",
  "10.1038/nature08820",
  "10.1038/onc.2009.285",
  "10.1038/sj.onc.1207810",
  "10.1038/nature06641",
  "10.1038/sj.onc.1203732",
  "10.1038/sj.onc.1208645",
  "10.1038/sj.onc.1204480",
  "10.1038/nature04533",
  "10.1038/sj.onc.1204050",
  "10.1038/sj.onc.1207036",
  "10.1038/sj.onc.1208847",
  "10.1038/sj.onc.1208401",
  "10.1038/sj.onc.1210558",
  "10.1038/sj.onc.1207336",
  "10.1038/onc.2010.47",
  "10.1038/sj.onc.1208205",
  "10.1038/onc.2008.333",
  "10.1038/sj.onc.1204997",
  "10.1038/34214",
  "10.1038/46794",
  "10.1038/onc.2008.495",
  "10.1038/onc.2011.470",
  "10.1038/sj.onc.1203748",
  "10.1038/sj.onc.1203541",
  "10.1038/sj.onc.1209639",
  "10.1038/sj.onc.1207247",
  "10.1038/onc.2012.113",
  "10.1038/onc.2009.36",
  "10.1038/sj.onc.1206479",
  "10.1038/sj.onc.1204141",
  "10.1038/sj.onc.1207327",
  "10.1038/sj.onc.1207163",
  "10.1038/sj.onc.1204308",
  "10.1038/sj.onc.1209315",
  "10.1038/onc.2010.281",
  "10.1038/sj.onc.1209179",
  "10.1038/sj.onc.1207322",
  "10.1038/nature12040",
  "10.1038/sj.onc.1206353",
  "10.1038/35060068",
  "10.1038/sj.onc.1203739",
  "10.1038/sj.onc.1210549",
  "10.1038/nature05724",
  "10.1038/onc.2010.602",
  "10.1038/sj.onc.1206794",
  "10.1038/nature08107",
  "10.1038/sj.onc.1208466",
  "10.1038/nature01056",
  "10.1038/30741",
  "10.1038/sj.onc.1207641",
  "10.1038/nature00969",
  "10.1038/nature10539",
  "10.1038/nature08027",
  "10.1038/sj.onc.1210820",
  "10.1038/nature10953",
  "10.1038/nature07221",
  "10.1038/sj.onc.1203883",
  "10.1038/sj.onc.1205474",
  "10.1038/sj.onc.1210010",
  "10.1038/sj.onc.1206724",
  "10.1038/onc.2008.425",
  "10.1038/nature04518",
  "10.1038/nature06730",
  "10.1038/nature07056",
  "10.1038/45822",
  "10.1038/sj.onc.1204847",
  "10.1038/sj.onc.1204553",
  "10.1038/sj.onc.1200963",
  "10.1038/onc.2009.430",
  "10.1038/sj.onc.1211022",
  "10.1038/sj.onc.1210805",
  "10.1038/ncb714",
  "10.1038/sj.onc.1207711",
  "10.1038/sj.onc.1207447",
  "10.1038/sj.onc.1210564",
  "10.1038/nature00889",
  "10.1038/nature07299",
  "10.1038/sj.onc.1209446",
  "10.1038/sj.onc.1204948",
  "10.1038/sj.onc.1208424",
  "10.1038/sj.onc.1203072",
  "10.1038/nature03868",
  "10.1038/sj.onc.1207775",
  "10.1038/nature03988",
  "10.1038/sj.onc.1204670",
  "10.1038/onc.2011.153",
  "10.1038/nature04185",
  "10.1038/nature09584",
  "10.1038/sj.onc.1206333",
  "10.1038/416451a",
  "10.1038/nature12210",
  "10.1038/29093",
  "10.1038/sj.onc.1207171",
  "10.1038/sj.onc.1203456",
  "10.1038/nature01385",
  "10.1038/sj.onc.1205260",
  "10.1038/nature08963",
  "10.1038/sj.onc.1207318",
  "10.1038/sj.onc.1207735",
  "10.1038/sj.onc.1208316",
  "10.1038/onc.2008.87",
  "10.1038/sj.onc.1210723",
  "10.1038/sj.onc.1201397",
  "10.1038/sj.onc.1203341",
  "10.1038/sj.onc.1208945",
  "10.1038/sj.onc.1210721",
  "10.1038/sj.onc.1208954",
  "10.1038/nature08106",
  "10.1038/35003228",
  "10.1038/sj.onc.1208510",
  "10.1038/sj.onc.1207656",
  "10.1038/sj.onc.1210254",
  "10.1038/sj.onc.1201413",
  "10.1038/44188",
  "10.1038/nature12313",
  "10.1038/sj.onc.1204585",
  "10.1038/sj.onc.1208871",
  "10.1038/nature05378",
  "10.1038/sj.onc.1201953",
  "10.1038/onc.2010.31",
  "10.1038/sj.onc.1205137",
  "10.1038/sj.onc.1206323",
  "10.1038/sj.onc.1210707",
  "10.1038/sj.onc.1208530",
  "10.1038/sj.onc.1207338",
  "10.1038/nature05656",
  "10.1038/nature08199",
  "10.1038/sj.onc.1204635",
  "10.1038/nature748",
  "10.1038/sj.onc.1202493",
  "10.1038/sj.onc.1204496",
  "10.1038/sj.onc.1205646",
  "10.1038/sj.onc.1205524",
  "10.1038/sj.onc.1205203",
  "10.1038/sj.onc.1204417",
  "10.1038/sj.onc.1205894",
  "10.1038/sj.onc.1203623",
  "10.1038/35065138",
  "10.1038/sj.onc.1203176",
  "10.1038/sj.onc.1209924",
  "10.1038/sj.onc.1205152",
  "10.1038/nature11055",
  "10.1038/sj.onc.1202532",
  "10.1038/sj.onc.1201727",
  "10.1038/sj.onc.1208256",
  "10.1038/sj.onc.1210846",
  "10.1038/35104560",
  "10.1038/35024009",
  "10.1038/sj.onc.1203664",
  "10.1038/sj.onc.1206782",
  "10.1038/417563a",
  "10.1038/sj.onc.1204127",
  "10.1038/sj.onc.1204477",
  "10.1038/sj.onc.1205748",
  "10.1038/sj.onc.1202885",
  "10.1038/sj.onc.1210440",
  "10.1038/sj.onc.1202227",
  "10.1038/17135",
  "10.1038/nature05164",
  "10.1038/sj.onc.1203564",
  "10.1038/sj.onc.1206999",
  "10.1038/sj.onc.1210186",
  "10.1038/sj.onc.1203820",
  "10.1038/sj.onc.1210313",
  "10.1038/sj.onc.1205184",
  "10.1038/sj.onc.1203164",
  "10.1038/sj.onc.1206908",
  "10.1038/sj.onc.1206735",
  "10.1038/sj.onc.1208719",
  "10.1038/sj.onc.1210133",
  "10.1038/sj.onc.1204504",
  "10.1038/70265",
  "10.1038/sj.onc.1208998",
  "10.1038/sj.onc.1205331",
  "10.1038/sj.onc.1209185",
  "10.1038/sj.onc.1207608",
  "10.1038/sj.onc.1201635",
  "10.1038/35070086",
  "10.1038/sj.onc.1205511",
  "10.1038/onc.2008.290",
  "10.1038/sj.onc.1204437",
  "10.1038/39062",
  "10.1038/35087069",
  "10.1038/sj.onc.1204049",
  "10.1038/nature04634",
  "10.1038/nature07955",
  "10.1038/sj.onc.1209487",
  "10.1038/sj.onc.1203587",
  "10.1038/nature01640",
  "10.1038/sj.onc.1202098",
  "10.1038/sj.onc.1209117",
  "10.1038/sj.onc.1210580",
  "10.1038/nature05969",
  "10.1038/sj.onc.1203852",
  "10.1038/sj.onc.1201373",
  "10.1038/sj.onc.1205980",
  "10.1038/sj.onc.1203299",
  "10.1038/sj.onc.1209932",
  "10.1038/sj.onc.1205981",
  "10.1038/nature06255",
  "10.1038/sj.onc.1203004",
  "10.1038/nature01587",
  "10.1038/onc.2008.439",
  "10.1038/sj.onc.1201140",
  "10.1038/35087620",
  "10.1038/sj.onc.1202410",
  "10.1038/sj.onc.1208636",
  "10.1038/sj.onc.1203468",
  "10.1038/sj.onc.1204983",
  "10.1038/nature08778",
  "10.1038/sj.onc.1202513",
  "10.1038/sj.onc.1210729",
  "10.1038/sj.onc.1205967",
  "10.1038/sj.onc.1208535",
  "10.1038/sj.onc.1204343",
  "10.1038/sj.onc.1203759",
  "10.1038/sj.onc.1210990",
  "10.1038/sj.onc.1206897",
  "10.1038/sj.onc.1203793",
  "10.1038/sj.onc.1201778",
  "10.1038/nature04431",
  "10.1038/sj.onc.1202363",
  "10.1038/sj.onc.1206034",
  "10.1038/d41586-021-00733-5",
  "10.1038/sj.onc.1208891",
  "10.1038/onc.2008.178",
  "10.1038/sj.onc.1208146",
  "10.1038/nature01137",
  "10.1038/sj.onc.1202714",
  "10.1038/sj.onc.1203444",
  "10.1038/35066610",
  "10.1038/44586",
  "10.1038/sj.onc.1210037",
  "10.1038/35036365",
  "10.1038/sj.onc.1205534",
  "10.1038/sj.onc.1204627",
  "10.1038/sj.onc.1208038",
  "10.1038/sj.onc.1202179",
  "10.1038/sj.onc.1210386",
  "10.1038/35044100",
  "10.1038/sj.onc.1210516",
  "10.1038/ncb1001-905",
  "10.1038/sj.onc.1205896",
  "10.1038/sj.onc.1204372",
  "10.1038/sj.onc.1210621",
  "10.1038/nature07035",
  "10.1038/sj.onc.1210266",
  "10.1038/sj.onc.1205348",
  "10.1038/sj.onc.1202925",
  "10.1038/sj.onc.1204313",
  "10.1038/sj.onc.1205934",
  "10.1038/sj.onc.1208828",
  "10.1038/sj.onc.1208436",
  "10.1038/nature02110",
  "10.1038/sj.onc.1202603",
  "10.1038/sj.onc.1207737",
  "10.1038/415339a",
  "10.1038/35038090",
  "10.1038/sj.onc.1209212",
  "10.1038/sj.onc.1204617",
  "10.1038/sj.onc.1209735",
  "10.1038/35023595",
  "10.1038/sj.onc.1209825",
  "10.1038/sj.onc.1210860",
  "10.1038/nature12137",
  "10.1038/sj.onc.1201172",
  "10.1038/d41586-020-01294-9",
  "10.1038/35093131",
  "10.1038/sj.onc.1205782",
  "10.1038/sj.onc.1207153",
  "10.1038/d41586-019-02023-7",
  "10.1038/onc.2008.249",
  "10.1038/d41586-018-01314-9",
];
