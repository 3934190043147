import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, List, ListItemButton, ListItemText, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchComment } from "../../../store/functions/fetchData";
import { issueActions } from "../../../store/issueSlice";
import ClickAwayListener from "@mui/material/ClickAwayListener";
//CommentBox component - displays the dropdown for the comments + their description
const CommentBox = () => {
  const [open, setOpen] = useState(false);
  const [displayedComment, setDisplayedComment] = useState(0);
  const comment = useSelector((state) => state.comment.comment);
  let description = null;
  if (comment) {
    description = comment.description;
  }
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.token);
  const comments = useSelector((state) => state.comment.comments);
  const issues = useSelector((state) => state.issue.issues);
  const doi = useSelector((state) => state.doi.doi);

  useEffect(() => {
    //when the selected comment changes, a new set of data will be fetched
    if (comments && !issues && comments[displayedComment])
      dispatch(fetchComment(token, comments[displayedComment].uuid, doi));
  }, [displayedComment, comments, dispatch, issues, token]);
  function handleClick() {
    setOpen(!open);
  }

  function selectComment(index) {
    setDisplayedComment(index);
    dispatch(issueActions.deassignIssue());
  }
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Paper
        elevation={2}
        sx={{
          width: "100%",
          backgroundColor: "#f4f4f4",
        }}
      >
        <List disablePadding sx={{ maxHeight: "100%" }}>
          <ListItemButton
            onClick={handleClick}
            sx={{
              overflow: "auto",
              display: "flex",
              alignItems: "start",
              height: 110,
            }}
          >
            <ListItemText
              sx={{
                overflow: "auto",
              }}
              primaryTypographyProps={{
                component: "span",
                variant: "subtitle1",
              }}
              secondary={`${description}`}
              primary={"Comment description:"}
            />
            {open ? (
              <ExpandLess sx={{ alignSelf: "center" }} />
            ) : (
              <ExpandMore sx={{ alignSelf: "center" }} />
            )}
          </ListItemButton>
          <Paper sx={{ position: "absolute", zIndex: 10, width: "100%" }}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{
                backgroundColor: "#f8f8f8",
                overflow: "auto",
                width: "100%",
                maxHeight: 450,
              }}
            >
              <List component="div">
                <ListItemButton
                  sx={{
                    pl: 6,
                    backgroundColor: "#cccccc",
                    "&.Mui-disabled": {
                      opacity: 0.8,
                    },
                  }}
                  disabled
                >
                  <ListItemText
                    primaryTypographyProps={{
                      component: "span",
                      variant: "subtitle2",
                    }}
                    primary={comments?.find((item) => item.uuid === comment?.uuid)?.description}
                  />
                </ListItemButton>
                {Array.isArray(comments)
                  ? comments.map((item, index) => {
                      return (
                        index !== displayedComment && (
                          <ListItemButton
                            key={index}
                            sx={{ pl: 6 }}
                            onClick={() => selectComment(index)}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                component: "span",
                                variant: "subtitle2",
                              }}
                              primary={item.description}
                            />
                          </ListItemButton>
                        )
                      );
                    })
                  : null}
              </List>
            </Collapse>
          </Paper>
        </List>
      </Paper>
    </ClickAwayListener>
  );
};

export default CommentBox;
