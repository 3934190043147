import { configureStore } from "@reduxjs/toolkit";
import tokenSlice from "./tokenSlice";
import commentSlice from "./commentSlice";
import issueSlice from "./issueSlice";
import doiSlice from "./doiSlice";
import aggregatedImagesSlice from "./aggregatedSlice";

const store = configureStore({
  reducer: {
    token: tokenSlice.reducer,
    doi: doiSlice.reducer,
    comment: commentSlice.reducer,
    issue: issueSlice.reducer,
    aggregatedImages: aggregatedImagesSlice.reducer,
  },
});

export default store;
